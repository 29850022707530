export default Object.freeze({
    install(Vue) {
        Vue.config.globalProperties.numberWithCommas = function (number) {
            if (number.toString().split(".").length === 2) {
                let parts = number.toString().split(".")
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                parts[1] = parts[1].slice(0, 4)
                return parts.join('.')
            } else {
                return (number + '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
        }

        Vue.config.globalProperties.orderStatus = function (status) {
            if (status === '0') {
                return '접수대기'
            } else if (status === '1') {
                return '입금완료'
            } else if (status === '2') {
                return '일부입금'
            } else if (status === '3') {
                return '처리불가'
            }
        }

        Vue.config.globalProperties.ticketStatus = function (status) {
            if (status === '0') {
                return '접수대기'
            } else if (status === '1') {
                return '입금완료'
            } else if (status === '2') {
                return '처리불가'
            }
        }

        Vue.config.globalProperties.maskingName = function (name) {
            if (name.length > 2) {
                let originName = name.split('')
                originName.forEach(function (name, i) {
                    if (i === 0 || i === originName.length - 1) return
                    originName[i] = '*'
                })
                let joinName = originName.join()
                return joinName.replace(/,/g, '')
            } else if (name.length === 2){
                let pattern = /.$/;
                return name.replace(pattern, '*')
            } else {
                return name
            }
        }
    }
})
