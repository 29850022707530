import {createApp} from 'vue'
import App from './App.vue'
import router from './router'

import api from "@/commonjs/api";
import common from '@/commonjs/common';
import event from '@/commonjs/event';
import filter from '@/commonjs/filter';
import mitt from "mitt";

router.beforeEach((to, from, next) => {
    let tokenMiddle = [
        'MyOrder',
        'MyOrderDetail',
        'RealTimePurchaseStatusDetail',
    ]

    if (tokenMiddle.indexOf(to.name) !== -1) {
        let token = sessionStorage.getItem('myOrderToken')
        if (!token) {
            next({name: 'Main'})
        }
    }

    next()
})

const app = createApp(App)

app.use(router)
app.use(api)
app.use(common)
app.use(event)
app.use(filter)

let emitter = mitt()
app.config.globalProperties.emitter = emitter

app.mount('#app')

