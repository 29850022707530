<template>
  <div>
    <!-- 서브상단타이틀 -->
    <div class="visual_sub customer">
      <h2>1:1 문의</h2>
      <p>문의글을 남겨주시면 <br />순차적으로 답변드리고 있습니다</p>
    </div>
    <!-- //서브상단타이틀 -->
    <!-- container -->
    <div id="container" class="customer qna">
      <div class="wrap lock">

        <h3>비밀글 기능으로 보호된 글입니다.</h3>
        <p>작성자와 관리자만 열람하실 수 있습니다. <br />본인이라면 비밀번호를 입력하세요.</p>
        <dl>
          <dt>글제목 : {{ title }}</dt>
          <dd>작성자 : {{ name }}</dd>
        </dl>
        <div class="pass">
          <TCInput
              :type="'password'"
              :placeholder="'비밀번호 입력'"
              :model="password"
              :max-length="20"
              @update="(_model) => {this.password=_model}"
              @keyup.enter="confirm()"
          />
          <a @click="confirm()">확인</a>
        </div>
        <a class="btn_back" @click="$router.back()">이전 페이지로</a>
      </div>
    </div>
    <!-- //container -->
  </div>
</template>

<script>
import TCInput from "@/components/common/TCInput";
export default {
  name: "QnaPasswordConfirm",
  components: {
    TCInput
  },
  data() {
    return {
      id: '',
      title: '',
      name: '',
      password: '',
    }
  },
  mounted() {
    this.scrollTop()

    this.id = this.$route.query.id
    this.title = this.$route.query.title
    this.name = this.$route.query.name
  },
  methods: {
    confirm(){ // 확인
      if (this.password === '') {
        this.notify('error', '비밀번호를 입력해주세요')
        return
      }

      this.$router.push({ name: 'QnaDetail', query: {id: this.id, password: this.password}})
    },
    cancel(){ // 돌아가기
      this.$router.back()
    }
  }
}
</script>

<style scoped>

</style>