<template>
  <div>
    <!-- HEADER -->
    <header id="header">
      <div class="wrap">
        <h1 v-if="siteInfo">
          <a @click="goPage('Main')"
             :style="{ background: 'url(' + $DOMAIN + siteInfo.site_top_logo + ') no-repeat center center/100%'}">마이티켓</a>
        </h1>
        <nav>
          <ul>
            <li><a :class="{'active':$route.name === 'PurchasePrice'}"
                   @click="goPage('PurchasePrice' )">매입시세</a></li>
            <li><a :class="{'active':$route.name === 'ApplyExchangeCash'}"
                   @click="goPage( 'ApplyExchangeCash' )">현금교환 신청</a></li>
            <li><a
                :class="[{'active':$route.name === 'RealTimePurchaseStatus'},{'active':$route.name === 'RealTimePurchaseStatusDetail'}]"
                @click="goPage( 'RealTimePurchaseStatus' )">실시간 매입 현황</a></li>
            <li><a :class="[{'active':$route.name === 'NoticeList'}, {'active':$route.name === 'NoticeDetail'}, {'active':$route.name === 'FaqList'},
            {'active':$route.name === 'QnaList'},{'active':$route.name === 'QnaDetail'},{'active':$route.name === 'QnaPasswordConfirm'},{'active':$route.name === 'QnaRegister'}]"
                   @click="goPage('NoticeList')">고객센터</a></li>
          </ul>
        </nav>
        <a class="btn_blue" @click="orderCheck()">내 주문 조회</a>
        <a class="btn_m_menu" :class="{'active' : showMobileMenu}" @click="showMobileMenu = !showMobileMenu">
          <div></div>
          <div></div>
          <div></div>
        </a>
      </div>
    </header>
    <!-- //HEADER -->

    <!-- 모바일메뉴 -->
    <div id="m_menu" :class="{'active' : showMobileMenu}">
      <ul>
        <li><a @click="goPage('PurchasePrice' )">매입시세</a></li>
        <li><a @click="goPage( 'ApplyExchangeCash' )">현금교환 신청</a></li>
        <li><a @click="goPage( 'RealTimePurchaseStatus' )">실시간 매입 현황</a></li>
        <li><a @click="goPage('NoticeList')">고객센터</a></li>
      </ul>
    </div>
    <!-- //모바일메뉴 -->
  </div>
</template>

<script>
export default {
  name: "MenuBar",
  props: ['siteInfo'],
  data() {
    return {
      showMobileMenu: false,
    }
  },
  mounted() {
    document.addEventListener('scroll', this.scrollEvent)
  },
  beforeUnmount() {
    document.removeEventListener('scroll', this.scrollEvent)
  },
  methods: {
    scrollEvent() {
      // 헤더그림자
      if (window.scrollY > 10) {
        window.document.getElementById('header').classList.add('shadow')
      } else {
        window.document.getElementById('header').classList.remove('shadow')
      }
    },
    orderCheck() { // 주문조회
      this.$root.$refs.InputAlert.showInputAlert(
          (_token) => {
            sessionStorage.setItem('myOrderToken', _token)
            this.goPage('MyOrder')
          }, 0)
    },
    goPage(_name) {
      if (_name === this.$route.name){
        this.$router.go(0)
      } else {
        this.$router.push({name: _name})
      }

      if (this.showMobileMenu){
        this.showMobileMenu = false
      }
    }
  },
  watch: {
    showMobileMenu(){
      if (this.showMobileMenu){
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    }
  }
}
</script>

<style scoped>

</style>