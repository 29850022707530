<template>
  <div>
    <!-- 서브상단타이틀 -->
    <div class="visual_sub customer">
      <h2>자주하는 질문</h2>
      <p>고객센터 및 상품권 매입 <br/>24시간 운영됩니다</p>
    </div>
    <!-- //서브상단타이틀 -->
    <!-- container -->
    <div id="container" class="customer faq">
      <div class="dep2">
        <ul>
          <li><a @click="$router.push({ name : 'NoticeList' })">공지사항</a></li>
          <li class="on" @click="$router.go(0)"><a>자주하는 질문</a></li>
          <li><a @click="$router.push({ name : 'QnaList' })">1:1 문의</a></li>
        </ul>
      </div>
      <div class="wrap">
        <!-- FAQ 없을 때 -->
        <div v-if="loaded && faqList.length === 0" class="no-data">
          <p>#자주하는 질문 내역이 없습니다.</p>
        </div>

        <!-- FAQ 있을 때 -->
        <!-- 반복 -->
        <dl v-else class="faq" v-for="(faq, index) in faqList" :key="index">
          <dt :id="'dt' + index" @click="selectedFaq(index)"><em>Q</em>{{ faq.title }}
          </dt>
          <dd v-html="faq.content">
          </dd>
        </dl>
        <!-- //반복 -->
      </div>
    </div>
    <!-- //container -->
  </div>
</template>

<script>
export default {
  name: "FaqList",
  data() {
    return {
      loaded: false,
      faqList: []
    }
  },
  mounted() {
    this.scrollTop()

    this.getFaqList()
  },
  methods: {
    getFaqList() { // faq 가져오기
      this.loaded = false

      this.$get(this.$BOARD_FAQ_LIST, 'faq_list_get_faq_list', (result) => {
        this.faqList = result.data

        this.loaded = true

      }, (result) => {
        this.httpError(result)
      })
    },
    selectedFaq(_index) {
      for (let i = 0; i < this.faqList.length; i++) {
        if (i === _index) {
          this.faqList[i].expanded = !this.faqList[i].expanded

          window.$(document.getElementById('dt' + _index)).next('dd').siblings('dd').slideToggle(200);
          if (this.faqList[i].expanded) {
            window.$(document.getElementById('dt' + _index)).addClass('on');
          } else {
            window.$(document.getElementById('dt' + _index)).removeClass('on');
          }

          window.$(document.getElementById('dt' + _index)).next('dd').stop().slideToggle(200);

        } else {
          if (this.faqList[i].expanded) {
            this.faqList[i].expanded = false

            window.$(document.getElementById('dt' + i)).next('dd').siblings('dd').slideToggle(200);
            window.$(document.getElementById('dt' + i)).removeClass('on');
            window.$(document.getElementById('dt' + i)).next('dd').stop().slideToggle(200);
          }
        }
      }
    },
  }
}
</script>

<style scoped>

</style>