<template>
  <div id="app">
    <menu-bar :site-info="siteInfo"></menu-bar>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <bottom-footer :site-info="siteInfo"></bottom-footer>
    <div id="alert-wrap" class="alert-wrap"></div>
    <input-alert ref="InputAlert"></input-alert>
    <calculator ref="Calculator"></calculator>
    <terms01 ref="Terms01"></terms01>
    <terms02 ref="Terms02"></terms02>

    <!-- 우측퀵배너 -->
    <ul id="float_banner">
      <li class="chat">
        <a style="display: none;" @click="contactKakaotalk()"><em></em><span>카톡 채팅 상담</span></a>
      </li>
      <li class="won">
        <a class="btn_won" @click="openCalculator()"><em></em><span>실수령액 계산기</span></a>
      </li>
    </ul>
    <!-- //우측퀵배너 -->
  </div>
</template>

<script>

import Terms01 from "@/components/common/Terms01";
import Terms02 from "@/components/common/Terms02";
import Calculator from "@/components/common/Calculator";
import BottomFooter from "@/components/common/BottomFooter";
import MenuBar from "@/components/common/MenuBar";
import InputAlert from "@/components/common/InputAlert";

export default {
  name: 'App',
  components: {
    Terms01,
    Terms02,
    Calculator,
    BottomFooter,
    MenuBar,
    InputAlert
  },
  data() {
    return {
      siteInfo: null
    }
  },
  created() {
    this.emitter.on(this.$EVENT_SITE_INFO, () => {
      this.setSiteInfo()
    })
  },
  mounted() {
    this.$root.$refs.InputAlert = this.$refs.InputAlert
    this.$root.$refs.Calculator = this.$refs.Calculator
    this.$root.$refs.Terms01 = this.$refs.Terms01
    this.$root.$refs.Terms02 = this.$refs.Terms02

    if (localStorage.getItem('siteInfo')) {
      this.setSiteInfo()
    }
  },
  methods: {
    setSiteInfo() {
      this.siteInfo = JSON.parse(localStorage.getItem('siteInfo'))
      let favicon = window.document.getElementById('favicon')
      favicon.href = this.$DOMAIN + this.siteInfo.site_favicon // favicon
      document.title = this.siteInfo.site_name // 타이틀
    },
    contactKakaotalk() {  // 카카오톡 오픈채팅 연결
      // test start // url 변경 필요
      window.openWeb('https://open.kakao.com/o/seuXMF9e')
      // test end
    },
    openCalculator() {
      this.$root.$refs.Calculator.showCalculator()
    },
  },
  beforeCreate() {
    let console = (function (oldCons) {
      return {
        log: function () {
          if (process.env.VUE_APP_DEBUG === "1") {
            oldCons.log.apply(console, arguments)
          }
        },
        warn: function () {
          if (process.env.VUE_APP_DEBUG === "1") {
            oldCons.warn.apply(console, arguments)
          }
        },
        error: function () {
          if (process.env.VUE_APP_DEBUG === "1") {
            oldCons.error.apply(console, arguments)
          }
        },
      };
    }(window.console));
    window.console = console
  },
}
</script>

<style>
#app {
  height: 100%;
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/
}
</style>
