<template>
  <!-- 서비스이용약관 -->
  <div id="mask" v-if="show" @click.self="close()">
    <!--    <div id="mask" ></div>-->
    <div id="pop_service" class="pop_layer center">
      <h2>서비스 이용약관</h2>
      <div class="pre_wrap">
<pre>
 제 1 장 총칙

제 1 조 목적
이 약관은 {{ companyName }}(이하 "회사"라 함)에서 운영하는 홈페이지( {{ companyName }}.com 이하 "{{ companyName }}"라 함)에서 제공하는 인터넷 관련 서비스(이하 "서비스"라고 합니다) 이용에 관한 제반 사항과 회사와 회원의 권리, 의무를 규정함을 목적으로 합니다.

제 2 조 용어의 정의
1."회사"라 함은 {{ companyName }}에서 운영하는 온라인 서비스 및 가상의 영업장과 실물 매장, 혹은 사업자의 의미로도 사용합니다.
2."서비스"라 함은 회사의 홈페이지 및 회사가 운영하는 인터넷 사이트 등에서 제공되는 인터넷상의 모든 웹서비스를(무선인터넷 서비스 포함)를 의미합니다.
3."이용자"라 함은 회사가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
4."회원"이라 함은 회사에 개인정보를 제공하고, 회사가 운영하는 인터넷 사이트에서 제공하는 서비스를 이용하기 위해 필요한 회원 ID와, PASSWORD를 정하는 등 소정의 절차를 거쳐 회사와 서비스 이용계약을 체결하고 서비스 이용 약관에 따라서비스를 제공받는 자를 의미합니다.
5."아이디(ID)"라 함은 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 문자와 숫자의 조합입니다.
6."비밀번호(PW)"라 함은 회원의 비밀 보호 및 회원 본인임을 확인하고 서비스에 제공되는 각종 정보의 보안을 위해 회원 자신이 설정하며 회사가 정하는 일정한 기준에 의해 표기한 암호 문자를 말합니다.
7."운영자"라 함은 서비스의 전반적인 관리와 원활한 운영을 위하여 회사에서 선정한 사람을 의미합니다.

제 3 조 약관의 명시와 개정
1.회사는 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명, 사업자 등록번호, 연락처(전화, 팩스, 전자우편 주소 등) 등을 이용자가 알 수 있도록 "홈페이지"의 초기화면(전면)에 게시합니다.
2.회사는 약관의 규제에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망이용촉진및정보보호등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률, 방문판매등에 관한 법률, 소비자보호법 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
3.회사가 약관을 개정할 경우에는 주요 개정 사유 및 내용을 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다.
4.이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호 지침 및 관계법령 또는 상관례에 따릅니다.

제 4 조 서비스의 제공 및 변경
1.회사는 다음과 같은 업무를 수행합니다
 ① 상품권 매매 및 관련 정보제공
 ② 기타 회사가 정하는 업무
2.회사는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 서비스의 내용을 변경할 수 있습니다.
3.전항의 경우 회사는 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, 회사의 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.

제 5 조 서비스의 중단
1.회사는 다음 각 호에 해당하는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다.
 ① 서비스용 설비의 확장, 보수 등 공사로 인한 부득이한 경우
 ② 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우
 ③ 회사가 통제하기 곤란한 사정으로 불가피하게 서비스 중단이 필요한 경우
 ④ 서비스 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
 ⑤ 새로운 서비스로의 교체 등 회사가 적절하다고 판단하는 경우
 ⑥ 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
 ⑦ 회사에서 사전에 이용자에게 공지하거나 통지한 경우
2.회사는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단 회사에 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.

제 6 조 회사의 의무
1.회사는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하는 데 최선을 다하여야 합니다.
2.회사는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함) 보호를 위한 보안 시스템을 갖추도록 노력합니다.

제 7 조 회원가입
1.이용자는 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다.
2.회원 가입 시 공인인증서, IPIN 인증, 휴대폰 인증, 기타 회사가 요구하는 본인 인증수단을 거쳐 본인확인이 된 경우에 한함.
3.회원은 홈페이지를 이용하거나 개인정보 관리 책임자를 통하여 제공한 개인 정보에 대한 열람, 정정, 삭제 및 탈퇴를 통한 동의 철회를 요구할 수 있습니다.

제 8 조 회원 탈퇴 및 자격 상실 등
1.회원이 서비스 이용계약을 해지하고자 할 경우에는 본인이 온라인 또는 회사가 정한 별도의 이용방법을 통해 회사에 해지 신청을 하여야 합니다. 이 경우 회사는 해당 회원의 탈퇴를 위한 절차를 밟아 해지 신청을 처리합니다.
2.회원이 회사의 사이트 내 질서를 어지럽히는 부당한 행위를 할 경우, 회사는 회원자격을 제한하거나 정지시킬 수 있습니다.
3.회사는 회원이 다음 각호의 1에 해당하는 행위를 하였을 경우에는 이용계약을 해지하거나 서비스의 이용을 중지할 수 있습니다.
 ① 이 약관 및 서비스 이용에 관련된 관계법령을 위반한 사실이 밝혀진 경우
 ② 서비스의 이용목적에 불합리한 용도로 이용하는 경우
 ③ 가입한 이름이 실명이 아닌 경우
 ④ 동일 사용자가 다른 ID로 이중등록을 한 경우
 ⑤ 범죄와 관련되었다고 추정되는 일체의 행위를 한 경우
 ⑥ 회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행하는 경우
 ⑦ 회원 ID와 PASSWORD 등 회원 고유정보를 타인에게 누설하거나 타인의 ID 및 PASSWORD를 도용한 경우
 ⑧ 서비스 운영을 고의로 방해하는 경우
 ⑨ 통신 설비의 오 동작이나 정보 등의 파괴를 유발하는 컴퓨터 바이러스 프로그램 등을 유포하는 경우
 ⑩ 기타 관계법령이나 회사가 정한 이용규칙 혹은 관계법령에 위반하여 더 이상의 서비스 제공이 곤란하다고 판단되는 경우
4.회사가 회원 자격을 제한·정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 "홈페이지"는 회원자격을 상실시킬 수 있습니다.
5.회사는 회원자격을 상실시키는 경우에는 회원 등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원 등록 말소 전에 소명할 기회를 부여합니다.
6.회원 탈퇴는 사이트의 회원탈퇴란을 통하여 하는 것을 원칙으로 합니다.

제 9 조 회원에 대한 통지
1.회사가 회원에 대한 통지를 하는 경우, 회원의 홈페이지에 제출한 전자우편 주소로 할 수 있습니다.
2.회사는 불특정 다수 회원 또는 전자우편 오류 회원에 대한 통지의 경우 7일 이상 홈페이지에 게시함으로써 개별 통지에 갈음할 수 있습니다.

제 10 조 개인 정보 보호
1.회사는 회원의 정보 수집시 회원관리와 서비스 이용에 필요한 최소한의 정보를 수집합니다. 다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다.
 ① 성명
 ② 회원 ID
 ③ 비밀번호(Password)
 ④ E-mail 주소
 ⑤ 휴대폰 번호
 ⑥ 계좌 정보
 ⑦ 닉네임
2.홈페이지 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받으며, 이용자가 제공한 회원정보는 관련 법률상의 요청, 전화 또는 전자우편을 통해 {{ companyName }}에서 제공하는 서비스와 상품을 안내하는 등의 마케팅 활동에 활용될 수 있습니다.
3.제공된 회원의 개인정보는 해당 회원의 동의 없이 본래 목적 외의 용도로 이용하거나 제3자에게 제공할 수 없으며 이에 대한 모든 책임은 회사가 부담합니다. 단, 다음의 경우에는 예외로 합니다.
 ① 회사의 협력업체 또는 제휴상품을 선택한 회원의 경우 서비스를 계속 사용할 수 있도록 하기 위하여 서비스 이용에 필요한 제한된 범위의 개인정보를 협력업체 또는 제휴업체와 공유할 필요가 있는 경우
 ② 기타 법령에 의해 제공된 경우
4.회사가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련 사항(제공받는 자, 제공 목적 및 제공할 정보의 내용)등 정보통신망이용촉진 및 정보보호등에 관한 법률 제22조 제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
5.이용자는 언제든지 회사가 가지고 있는 자신의 개인 정보에 대해 열람 및 오류 정정을 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
6.회사는 개인정보 보호를 위하여 관리자를 한정하여 이용자의 개인정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
7.회사 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집 목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.

제 11 조 연결 홈페이지와 피연결 홈페이지 간의 관계
1.상위 "홈페이지"와 하위 "홈페이지"가 하이퍼 링크(예: 하이퍼 링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우, 전자를 연결 "홈페이지" (웹 사이트)라고 하고 후자를 피연결 "홈페이지"(웹사이트)이라고 합니다.
2.연결 "홈페이지"는 피연결 "홈페이지"가 독자적으로 제공하는 재화.용역에 의하여 이용자와 행하는 거래에 대해서 보증책임을 지지 않는다는 뜻을 "홈페이지"의 사이트에서 명시한 경우에는 그 거래에 대한 보증책임을 지지 않습니다.

제 12 조 저작권의 귀속 및 이용 제한
1.회사가 제작한 웹 화면상의 디자인 및 문구, 기타 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.
2.이용자는 사이트를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 출판, 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.

제 13 조 분쟁 해결
1.회사는 이용자가 제기하는 정당한 의견이나 불만을 제시하고자 하는 경우에는 고객센터에 의견을 제출해 주시면 됩니다.
2.회사는 이용자로부터 제출되는 불만사항 및 의견이 정당하다고 판단되는 경우 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리 일정을 즉시 통보해 드립니다.
3.회사와 이용자 간에 발생한 분쟁은 전자거래기본법 제30조 및 동 시행령 제15조에 의하여 설치된 전자거래분쟁조정위원회의 조정에 따를 수 있습니다.

제 14 조 재판권 및 준거권
1.회사와 이용자 간에 발생한 전자거래 분쟁에 관한 소송은 민사소송법상의 관할법원에 제기합니다.
2.회사와 이용자 간에 제기된 전자거래 소송에는 대한민국 법을 적용합니다.
</pre>
      </div>
      <a class="pop_close" @click="close()">닫기</a>
    </div>
  </div>
  <!-- //서비스이용약관 -->
</template>

<script>
export default {
  name: "Terms02",
  data() {
    return {
      show: false,
      companyName: ''
    }
  },
  methods: {
    showTerms() {
      if (localStorage.getItem('siteInfo')) {
        this.companyName = JSON.parse(localStorage.getItem('siteInfo')).company_name
      }

      this.show = true
    },
    close() {
      this.show = false
    },
  },
}
</script>

<style scoped>

</style>