<template>
  <transition name="fade">
    <div id="mask" v-if="show" @click.self="close()">
      <!-- 내주문조회 -->
      <!--    <div id="mask"></div>-->
      <div id="pop_my" class="pop_layer center">
        <h2 v-if="type === 0">내 주문 조회</h2>
        <h2 v-else>본인 인증</h2>
        <p>신청시 입력한 고객명과 휴대폰번호를 입력해주세요.</p>
        <ul>
          <li>
            <label>고객명</label>
            <TCInput
                :type="'text'"
                :model="name"
                :max-length="30"
                @update="(_model) => {this.name=_model}"
            />
          </li>
          <li>
            <label>휴대폰 번호</label>
            <TCInput
                :type="'text'"
                :placeholder="'숫자만 입력'"
                :model="phone"
                :input-mode="'numeric'"
                :max-length="20"
                @update="(_model) => {this.phone=_model}"
            />
          </li>
        </ul>
        <a class="submit" @click="ok()">조회하기</a>
        <a class="pop_close" @click="close()">닫기</a>
      </div>
      <!-- //내주문조회 -->
    </div>
  </transition>
</template>

<script>
import TCInput from "@/components/common/TCInput";

export default {
  name: "InputAlert",
  components: {
    TCInput
  },
  data() {
    return {
      show: false,
      name: '',
      phone: '',
      type: 0,  // 0: 주문조회, 1: 실시간매입현황(본인인증)
      callback: null,
    }
  },
  methods: {
    showInputAlert(callback, type = 0) {
      this.callback = callback
      this.type = type

      this.clearInput()
      this.show = true
    },
    clearInput() {
      this.name = ''
      this.phone = ''
    },
    close() {
      this.show = false
    },
    ok() { // 인증하기
      if (this.name === '') {
        this.notify('error', '고객명을 입력해주세요')
        return
      }

      if (this.phone === '') {
        this.notify('error', '휴대폰 번호를 입력해주세요')
        return
      }

      this.$post(this.$MYORDER_GET_TOKEN + '?name=' + this.name + '&phone=' + this.phone, 'input_alert_ok', null, (result) => {
        this.close()
        this.callback(result.data.Token)

      }, (result) => {
        this.httpError(result)
      })
    }
  }
}
</script>

<style scoped>

</style>