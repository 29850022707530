import { createRouter, createWebHashHistory } from "vue-router"

import Main from "@/components/main/Main";
import MyOrder from "@/components/myorder/MyOrder";
import MyOrderDetail from "@/components/myorder/MyOrderDetail";
import PurchasePrice from "@/components/price/PurchasePrice";
import ApplyExchangeCash from "@/components/apply/ApplyExchangeCash";
import RealTimePurchaseStatus from "@/components/status/RealTimePurchaseStatus";
import RealTimePurchaseStatusDetail from "@/components/status/RealTimePurchaseStatusDetail";
import NoticeList from "@/components/customerCenter/notice/NoticeList";
import NoticeDetail from "@/components/customerCenter/notice/NoticeDetail";
import FaqList from "@/components/customerCenter/faq/FaqList";
import QnaList from "@/components/customerCenter/qna/QnaList";
import QnaDetail from "@/components/customerCenter/qna/QnaDetail";
import QnaRegister from "@/components/customerCenter/qna/QnaRegister";
import QnaPasswordConfirm from "@/components/customerCenter/qna/QnaPasswordConfirm";

export default createRouter({
    history: createWebHashHistory(),//2.x대를 설치하시면 작동을 안합니다.
    scrollBehavior (){
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'Main',
            component: Main,
        },
        // 내 주문조회
        {
            path: '/my/order',
            name: 'MyOrder',
            component: MyOrder,
        },
        {
            path: '/my/order/detail',
            name: 'MyOrderDetail',
            component: MyOrderDetail,
        },
        // 상품권매입 시세
        {
            path: '/purchase/price',
            name: 'PurchasePrice',
            component: PurchasePrice,
        },
        // 상품권현금교환 신청
        {
            path: '/apply/exchange/cash',
            name: 'ApplyExchangeCash',
            component: ApplyExchangeCash,
        },
        // 실시간매입 현황
        {
            path: '/realtime/purchase/status',
            name: 'RealTimePurchaseStatus',
            component: RealTimePurchaseStatus,
        },
        {
            path: '/realtime/purchase/status/detail',
            name: 'RealTimePurchaseStatusDetail',
            component: RealTimePurchaseStatusDetail,
        },
        // 고객센터 (공지, 자주묻는 질문, 1:1문의)
        {
            path: '/notice/list',
            name: 'NoticeList',
            component: NoticeList,
        },
        {
            path: '/notice/detail',
            name: 'NoticeDetail',
            component: NoticeDetail,
        },
        // 자주묻는 질문
        {
            path: '/faq/list',
            name: 'FaqList',
            component: FaqList,
        },
        // 1:1 문의
        {
            path: '/qna/list',
            name: 'QnaList',
            component: QnaList,
        },
        {
            path: '/qna/detail',
            name: 'QnaDetail',
            component: QnaDetail,
        },
        {
            path: '/qna/register',
            name: 'QnaRegister',
            component: QnaRegister,
        },
        {
            path: '/qna/password/confirm',
            name: 'QnaPasswordConfirm',
            component: QnaPasswordConfirm,
        },
    ],
})
