export default Object.freeze({
    install(Vue) {
        // 토스트 스타일 알림
        Vue.config.globalProperties.notify = function (type, msg, is_alert = false) {
            if (is_alert) {
                alert(msg)
            } else {
                let originMsg = window.$('#alert-wrap div:first-child span').text()

                if (originMsg !== msg) {
                    if (type === 'error') {
                        window.$('#alert-wrap').append("<div class=\"alert-content bg-red\"><span>" + msg + "</span></div>")
                    } else {
                        window.$('#alert-wrap').append("<div class=\"alert-content\"><span>" + msg + "</span></div>")
                    }
                    setTimeout(function () {
                        window.$('#alert-wrap div:first-child').remove()
                    }, 3000)
                }
            }
        }

        // 기본 HTTP 에러 처리
        Vue.config.globalProperties.httpError = function (data) {
            if (data.data.msg && data.data.msg.length > 0) {
                this.notify('error', data.data.msg, false)
            }
        }

        // scrollTop으로 이동
        Vue.config.globalProperties.scrollTop = function () {
            window.scrollTo(0, 0)
        }
    }
})
