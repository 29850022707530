<template>
  <!-- 레이어팝업은행선택 -->
  <div id="mask" @click.self="$emit('close')">
    <!--    <div id="mask"></div>-->
    <div id="pop_bank" class="pop_layer center">
      <h2>입금은행 선택</h2>
      <input type="text" placeholder="은행 검색" v-model="searchKeyword" maxlength="20"/>
      <div class="list">
        <ul>
          <!-- 반복 -->
          <li v-for="(bank, index) in bankList" :key="index">
            <a @click="selected(bank)">
              <em v-if="bank.bank_img === null || bank.bank_img === ''"></em>
              <img v-else :src="$DOMAIN + bank.bank_img" alt="은행로고">
              <span>{{ bank.bank_name }}</span>
            </a>
          </li>
          <!-- //반복 -->
        </ul>
      </div>
      <a class="pop_close" @click="$emit('close')">닫기</a>
    </div>
  </div>
  <!-- //레이어팝업은행선택 -->
</template>

<script>
export default {
  name: "ApplySelectBank",
  props: ['bankList'],
  data() {
    return {
      searchKeyword: '',
    }
  },
  methods: {
    searched() {
      this.$emit('searched', this.searchKeyword)
    },
    selected(_bank) {
      this.$emit('selected', _bank)
    },
  },
  watch: {
    searchKeyword() {
      this.searched()
    },
  },
}
</script>

<style scoped>

</style>