<template>
  <div>
    <!-- 서브상단타이틀 -->
    <div class="visual_sub price">
      <h2>실시간 매입시세</h2>
      <p>실시간 매입시세 입니다 <br />매입시세는 상황에 따라 변동될 수 있습니다</p>
    </div>
    <!-- //서브상단타이틀 -->
    <!-- container -->
    <div id="container" class="price">
      <div class="wrap">
        <h3>실시간 매입시세</h3>
        <div class="tbl">
          <p class="tp">{{ date }} 기준(현재)</p>
          <table>
            <thead>
            <tr>
              <th>상품권 종류</th>
              <th>기준가</th>
              <th>파실 때</th>
              <th>매입가(%)</th>
              <th>대량판매</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(ticket, index) in ticketList" :key="index">
              <th>{{ ticket.ticket_name }}</th>
              <td>10,000</td>
              <td>{{ numberWithCommas(ticket.sales_price.toFixed(0)) }}</td>
              <td>{{ ticket.purchase_rate }}%</td>
              <td><span>문의</span></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- //container -->
  </div>
</template>

<script>
export default {
  name: "PurchasePrice",
  data(){
    return {
      ticketList: [],
      date: ''
    }
  },
  mounted() {
    this.getTicketList()

    let current = new Date()
    let year = current.getFullYear()
    let month = current.getMonth() + 1
    let day = current.getDate()

    this.date = year + '. ' + month + '. ' + day + '.'
  },
  methods: {
    getTicketList(){ // 매입시세 가져오기
      this.$get(this.$TICKET_LIST, 'purchase_price_get_ticket_list', (result) => {
        this.ticketList = result.data

        for (let i=0 ; i < this.ticketList.length ; i ++){
          let ticket = this.ticketList[i]
          let salesPrice = 10000 * ( ticket.purchase_rate * 0.01 )

          ticket['sales_price'] = salesPrice
        }

      }, (result) => {
        this.httpError(result)
      })
    }
  }
}
</script>

<style scoped>

</style>