<template>
  <div>
    <!-- 서브상단타이틀 -->
    <div class="visual_sub customer">
      <h2>1:1 문의</h2>
      <p>문의글을 남겨주시면 <br/>순차적으로 답변드리고 있습니다</p>
    </div>
    <!-- //서브상단타이틀 -->
    <!-- container -->
    <div id="container" class="customer qna">
      <div class="dep2">
        <ul>
          <li><a @click="$router.push({ name : 'NoticeList' })">공지사항</a></li>
          <li><a @click="$router.push({ name : 'FaqList' })">자주하는 질문</a></li>
          <li class="on" @click="reloadPage()"><a>1:1 문의</a></li>
        </ul>
      </div>
      <div class="wrap">
        <div class="search">
          <TCInput
              :type="'text'"
              :placeholder="'제목 또는 작성자명 입력'"
              :model="searchKeyword"
              :max-length="50"
              @update="(_model) => {this.searchKeyword=_model}"
              @keyup.enter="search()"
          />
          <a @click="search()">검색</a>
        </div>
        <div class="tbl">
          <!-- 1:1 문의 없을 때 -->
          <div v-if="loaded && qnaList.length === 0" class="no-data">
            <p>#1:1문의 내역이 없습니다.</p>
          </div>

          <!-- 1:1 문의 있을 때 -->
          <table v-else>
            <thead>
            <tr>
              <th class="num">번호</th>
              <th class="tt">제목</th>
              <th class="writer">작성자</th>
              <th class="date">작성일</th>
            </tr>
            </thead>
            <tbody>
            <!-- 반복 -->
            <tr v-for="(qna, index) in qnaList" :key="index" @click="goConfirmPassword(qna)">
              <td class="num">{{ totalSize - ((currentPage - 1) * size) - index }}</td>
              <td class="tt"><a><span v-if="qna.state === 1">답변완료</span>{{ qna.title }}</a></td>
              <td class="writer">{{ qna.name }}</td>
              <td class="date">{{ qna.created_at }}</td>
            </tr>
            <!-- //반복 -->
            </tbody>
          </table>
        </div>
        <div class="btn_write">
          <a @click="goRegister()">글쓰기</a>
        </div>
        <div class="paging" v-if="pages.length > 0">
          <ul>
            <li><a class="arr prev" @click="goPrev()">이전페이지</a></li>
            <!-- 반복 -->
            <li v-for="(page, index) in pages" :key="index">
              <a :class="{ 'on' : currentPage === page }" @click="getQnaList(page)">{{ page }}</a>
            </li>
            <!-- //반복 -->
            <li><a class="arr next" @click="goNext()">다음페이지</a></li>
          </ul>
        </div>
      </div>
    </div>
    <!-- //container -->
  </div>
</template>

<script>
import TCInput from "@/components/common/TCInput";

export default {
  name: "QnaList",
  components: {
    TCInput
  },
  data() {
    return {
      loaded: false,
      searchKeyword: '',
      qnaList: [],
      currentPage: 1,
      size: 10, // 한 페이지에 보여줄 size
      totalSize: 0, // 총 데이터 list length
      totalPage: 0,
      pages: [],
      ablePrev: false,
      ableNext: false,
    }
  },
  mounted() {
    if (this.$route.query.searchKeyword){
      this.searchKeyword = this.$route.query.searchKeyword
    }

    this.getQnaList(1)
  },
  methods: {
    reloadPage(){
      this.searchKeyword = ''
      this.search()
    },
    getQnaList(_page) { // 1:1문의 리스트 가져오기
      this.loaded = false

      let queryString = '?page=' + _page + '&perPage=' + this.size

      if (this.searchKeyword !== '') {
        queryString = queryString + '&searchWord=' + this.searchKeyword
      }

      this.$get(this.$BOARD_QNA_LIST + queryString, 'qna_list_get_qna_list', (result) => {
        this.size = result.data.per_page
        this.totalSize = result.data.total
        this.qnaList = result.data.data

        this.currentPage = _page
        this.pagination()

        this.loaded = true
        this.scrollTop()

      }, (result) => {
        this.httpError(result)
      })
    },
    goPrev() { /*이전*/
      if (this.ablePrev) {
        this.getQnaList(this.currentPage - 1)
      }
    },
    goNext() { /*다음*/
      if (this.ableNext) {
        this.getQnaList(this.currentPage + 1)
      }
    },
    pagination() {
      if (this.totalSize > this.size * this.currentPage) {
        this.ableNext = true
      } else {
        this.ableNext = false
      }
      this.ablePrev = (this.currentPage === 1 ? false : true)

      this.totalPage = Math.floor(this.totalSize % this.size === 0 ? this.totalSize / this.size : this.totalSize / this.size + 1)
      this.pages = []

      let startPage = (this.currentPage - 2) > 1 ? this.currentPage - 2 : 1 // 현재페이지 -2 ==> 1보다 크면, page-2 아니면 1
      let endPage = (this.currentPage + 2) > this.totalPage ? this.totalPage : this.currentPage + 2 // 현재페이지 +2 ==> 토탈보다 크면, 토탈 아니면 page+2

      if ((this.currentPage === 1 || this.currentPage === 2) && this.totalPage >= 5) { // 현재페이지 1 or 2이고, 토탈 5랑 같거나 크면 5
        endPage = 5
      } else if (this.currentPage === 1 && this.totalPage === 4) { // 현재페이지 1, 토탈 4
        endPage = 4
      }

      if (this.currentPage === this.totalPage) {
        if ((this.currentPage - 4) > 1) {
          startPage = this.currentPage - 4
        } else {
          startPage = 1
        }
      } else if (this.currentPage === (this.totalPage - 1)) {
        if ((this.currentPage - 3) > 1) {
          startPage = this.currentPage - 3
        } else {
          startPage = 1
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        this.pages.push(i)
      }
    },
    search(){
      if (this.searchKeyword === ''){
        this.$router.replace({ name: 'QnaList'})

      } else {
        this.$router.replace({ name: 'QnaList', query : { searchKeyword : this.searchKeyword }})
      }

      this.getQnaList(1)
    },
    goConfirmPassword(_qna) {  // 문의 비밀번호 확인
      this.$router.push({name: 'QnaPasswordConfirm', query: {id: _qna.id, title: _qna.title, name: _qna.name}})
    },
    goRegister() { // 문의 글쓰기
      this.$router.push({name: 'QnaRegister'})
    }
  },
}
</script>

<style scoped>

</style>