<template>
  <div>
    <!-- 서브상단타이틀 -->
    <div class="visual_sub customer">
      <h2>1:1문의</h2>
      <p>문의글을 남겨주시면 <br/>순차적으로 답변드리고 있습니다</p>
    </div>
    <!-- //서브상단타이틀 -->
    <!-- container -->
    <div id="container" class="customer qna_view">
      <div class="wrap">
        <!-- 게시판상단 -->
        <div class="heading">
          <h3>{{ title }}</h3>
          <ul>
            <li>{{ date }}</li>
            <li>{{ name }}</li>
          </ul>
        </div>
        <!-- //게시판상단 -->
        <!-- 게시판내용 -->
        <div class="con">
          {{ content }}
        </div>
        <div class="re" v-if="answer !== ''" v-html="answer">
        </div>
        <!-- //게시판내용 -->
        <a class="btn_back" @click="$router.go(-2)">목록으로 돌아가기</a>
      </div>
    </div>
    <!-- //container -->
  </div>
</template>

<script>
export default {
  name: "QnaDetail",
  data() {
    return {
      id: 0,
      password: '',
      title: '',
      date: '',
      name: '',
      content: '',
      answer: '',
    }
  },
  mounted() {
    this.scrollTop()

    this.id = this.$route.query.id
    this.password = this.$route.query.password

    this.getDetail()
  },
  methods: {
    getDetail() {
      this.$post(this.$BOARD_QNA_DETAIL + this.id + '?password=' + this.password, 'qna_detail_get_detail', null, (result) => {
        this.title = result.data.title
        this.date = result.data.created_at
        this.name = result.data.name
        this.content = result.data.content
        this.answer = result.data.reply

      }, (result) => {
        this.httpError(result)

        this.$router.back()
      })
    }
  }
}
</script>

<style scoped>

</style>