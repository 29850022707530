<template>
  <div>
    <!-- 서브상단타이틀 -->
    <div class="visual_sub customer">
      <h2>1:1문의</h2>
      <p>문의글을 남겨주시면 <br/>순차적으로 답변드리고 있습니다</p>
    </div>
    <!-- //서브상단타이틀 -->
    <!-- container -->
    <div id="container" class="customer qna_write">
      <div class="wrap">
        <div class="personal">
          <dl>
            <dt>이름<em>*</em></dt>
            <dd>
              <TCInput
                  :type="'text'"
                  :model="name"
                  :max-length="30"
                  @update="(_model) => {this.name=_model}"
              />
            </dd>
          </dl>
          <dl>
            <dt>비밀번호<em>*</em></dt>
            <dd>
              <TCInput
                  :type="'password'"
                  :model="password"
                  :max-length="20"
                  @update="(_model) => {this.password=_model}"
              />
            </dd>
          </dl>
        </div>
        <dl>
          <dt>제목<em>*</em></dt>
          <dd>
            <TCInput
                :type="'text'"
                :model="title"
                :max-length="50"
                @update="(_model) => {this.title=_model}"
            />
          </dd>
        </dl>
        <dl>
          <dt>문의내용<em>*</em></dt>
          <dd>
            <textarea v-model="content" maxlength="1500"></textarea>
          </dd>
        </dl>
        <div class="captcha">
          <h3>자동등록방지<em>*</em></h3>
          <img v-if="img.length > 0" :src="img" alt="캡챠이미지"/>
          <TCInput
              :type="'text'"
              :model="captcha"
              :max-length="6"
              @update="(_model) => {this.captcha=_model}"
          />
          <!--          <button class="speaker"></button>-->
          <button class="reload" @click="getCaptcha()"></button>
          <p>왼쪽에 보이는 문자를 순서대로 입력하세요</p>
        </div>
        <div class="btn_wrap">
          <a class="cancel" @click="$router.back()">작성취소</a>
          <a class="submit" @click="completed()">작성완료</a>
        </div>
      </div>
    </div>
    <!-- //container -->
  </div>
</template>

<script>
import TCInput from "@/components/common/TCInput";

export default {
  name: "QnaRegister",
  components: {
    TCInput
  },
  data() {
    return {
      name: '',
      password: '',
      email: '',
      title: '',
      content: '',
      key: '',
      captcha: '',
      img: ''
    }
  },
  mounted() {
    this.scrollTop()

    this.getCaptcha()
  },
  methods: {
    getCaptcha() {  // 캡챠정보 가져오기
      this.$get(this.$BOARD_GET_CAPTCHA, 'qna_register_get_captcha', (result) => {
        this.key = result.key
        this.img = result.img

      }, (result) => {
        this.httpError(result)
      })
    },
    completed() {  // 작성완료
      if (this.name === '') {
        this.notify('error', '이름을 입력해주세요')
        return
      }

      if (this.password === '') {
        this.notify('error', '비밀번호를 입력해주세요')
        return
      }

      if (this.title === '') {
        this.notify('error', '제목을 입력해주세요')
        return
      }

      if (this.content === '') {
        this.notify('error', '문의내용을 입력해주세요')
        return
      }

      if (this.captcha === '') {
        this.notify('error', '자동등록방지 문자를 입력해주세요')
        return
      }

      let queryString = '?title=' + this.title + '&content=' + this.content + '&name=' + this.name + '&password=' + encodeURIComponent(this.password) +
          '&key=' + this.key + '&captcha=' + this.captcha

      this.$post(this.$BOARD_QNA_REGISTER + queryString, 'qna_register_completed', null, () => {
        this.notify('success', '문의 등록이 완료되었습니다.')
        this.$router.back()

      }, (result) => {
        this.httpError(result)

        if (result.status === 409){ // 자동등록방지 문자 오류시
          this.getCaptcha()
          this.captcha = ''
        }
      })
    },
    cancel() { // 취소
      this.$router.back()
    }
  },
}
</script>

<style scoped>

</style>