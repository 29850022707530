<template>
  <!-- 푸터 -->
  <footer v-if="siteInfo">
    <h1>{{ siteInfo.company_name }}</h1>
    <ul>
      <li><a class="btn_service" @click="openTerms(1)">서비스 이용약관</a></li>
      <li><a class="btn_privacy" @click="openTerms(0)">개인정보처리방침</a></li>
    </ul>
    <p><span>대표 : {{ siteInfo.company_ceo }}</span><span>주소 : {{ siteInfo.company_address }}</span></p>
    <p><span>사업자 등록번호 : {{ siteInfo.business_number }}</span></p>
    <p><span>대표번호 : {{ siteInfo.customer_tel }}</span></p>
    <p>※ {{ siteInfo.company_name }}은 상품권을 판매하는 업체가 아닙니다. <br>상품권 구매는 각 상품권 홈페이지를 이용해주세요.</p>
    <cite>Copyright &copy; {{ siteInfo.company_name }}. All Rights Reserved.</cite>
  </footer>
  <!-- //푸터 -->
</template>

<script>
export default {
  name: "BottomFooter",
  props: ['siteInfo'],
  methods: {
    openTerms(_type) {
      if (_type === 0) { // 개인정보 처리방침
        this.$root.$refs.Terms01.showTerms()
      } else {  // 서비스 이용약관
        this.$root.$refs.Terms02.showTerms()
      }
    },
  }
}
</script>

<style scoped>

</style>