<template>
  <div>
    <!-- 서브상단타이틀 -->
    <div class="visual_sub order">
      <h2>현금교환 신청</h2>
      <p>신청서 작성만으로 <br/>언제 어디서든 즉시 현금지급!</p>
    </div>
    <!-- //서브상단타이틀 -->
    <!-- container -->
<!--    <div id="container" class="order">-->
    <div id="contents" class="order">
      <div class="wrap">
        <!-- 신청폼 -->
        <div class="form_wrap">
          <form>
            <!-- 상품권종류 -->
            <div class="ticket">
              <h3>상품권 종류</h3>
              <ul>
                <!-- 반복 -->
                <li v-for="(ticket, index) in ticketList" :key="index" @click="selectTicket(ticket)">
                  <input type="radio" :id="'t' + index" name="ticket" :checked="ticket.id === selectedTicket.id"/>
                  <label :for="'t' + index">{{ ticket.ticket_name }}[{{ ticket.purchase_rate }}%]</label>
                </li>
                <!-- //반복 -->
              </ul>
            </div>
            <!-- //상품권종류 -->

            <!-- 핀번호추출 -->
            <div class="pin">
              <h3>상품권 핀번호 추출</h3>
              <textarea v-model="autoNumber" :placeholder="placeHolder"></textarea>
              <div class="btn_wrap">
                <span class="btn_gray" @click="clearAutoNumber()">비우기</span>
                <span class="btn_blue" @click="extractPinNumber()">추출하기</span>
              </div>
            </div>
            <!-- //핀번호추출 -->

            <!-- 상품권번호입력 -->
            <div class="number" v-if="selectedTicket !== null">
              <h3>상품권 번호 입력</h3>
              <div class="wrap" v-if="selectedTicket.id === 1"> <!-- 컬쳐랜드상품권(모바일문화상품권) -->
                <div class="input-1">
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo0"
                         ref="pinNo0"/>
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo1"
                         ref="pinNo1"/>
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo2"
                         ref="pinNo2"/>
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo3"
                         ref="pinNo3"/>
                  <TCInput
                      :type="'text'"
                      :placeholder="'금액 입력'"
                      :model="amount"
                      :input-mode="'numeric'"
                      :max-length="10"
                      @update="(_model) => {this.amount=_model}"
                  />
                </div>
                <span class="btn_blue" @click="add()">상품권 추가</span>
              </div>

              <div class="wrap" v-else-if="selectedTicket.id === 2"> <!-- 컬쳐랜드 문화상품권(18자리) -->
                <div class="input-1">
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo0"
                         ref="pinNo0"/>
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo1"
                         ref="pinNo1"/>
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo2"
                         ref="pinNo2"/>
                  <input inputmode="numeric" type="text" maxlength="6" placeholder="6자리" v-model="pinNo3"
                         ref="pinNo3"/>
                  <TCInput
                      :type="'text'"
                      :placeholder="'금액 입력'"
                      :model="amount"
                      :input-mode="'numeric'"
                      :max-length="10"
                      @update="(_model) => {this.amount=_model}"
                  />
                </div>
                <span class="btn_blue" @click="add()">상품권 추가</span>
              </div>

              <div class="wrap" v-else-if="selectedTicket.id === 3"> <!-- 3:해피머니 문화상품권 -->
                <div class="input-3">
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo0"
                         ref="pinNo0"/>
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo1"
                         ref="pinNo1"/>
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo2"
                         ref="pinNo2"/>
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo3"
                         ref="pinNo3"/>
                  <input inputmode="numeric" type="text" maxlength="8" placeholder="인증번호(발행일)"
                         v-model="pinNo4"
                         ref="pinNo4"/>
                  <TCInput
                      :type="'text'"
                      :placeholder="'금액 입력'"
                      :model="amount"
                      :input-mode="'numeric'"
                      :max-length="10"
                      @update="(_model) => {this.amount=_model}"
                  />
                </div>
                <span class="btn_blue" @click="add()">상품권 추가</span>
              </div>

              <div class="wrap" v-else-if="selectedTicket.id === 6">  <!-- 6:북앤라이프 도서상품권 -->
                <div class="input-3">
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo0"
                         ref="pinNo0"/>
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo1"
                         ref="pinNo1"/>
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo2"
                         ref="pinNo2"/>
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo3"
                         ref="pinNo3"/>
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="인증번호" v-model="pinNo4"
                         ref="pinNo4"/>
                  <TCInput
                      :type="'text'"
                      :placeholder="'금액 입력'"
                      :model="amount"
                      :input-mode="'numeric'"
                      :max-length="10"
                      @update="(_model) => {this.amount=_model}"
                  />
                </div>
                <span class="btn_blue" @click="add()">상품권 추가</span>
              </div>

              <div class="wrap" v-else-if="selectedTicket.id === 9">  <!-- 9:컬쳐랜드 캐시플러스 -->
                <div class="input-1">
                  <input type="text" maxlength="1" placeholder="1자리" v-model="pinNo5"
                         ref="pinNo5"/>
                  <input inputmode="numeric" type="text" maxlength="3" placeholder="3자리" v-model="pinNo0"
                         ref="pinNo0"/>
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo1"
                         ref="pinNo1"/>
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo2"
                         ref="pinNo2"/>
                  <TCInput

                      :type="'text'"
                      :placeholder="'금액 입력'"
                      :model="amount"
                      :input-mode="'numeric'"
                      :max-length="10"
                      @update="(_model) => {this.amount=_model}"
                  />
                </div>
                <span class="btn_blue" @click="add()">상품권 추가</span>
              </div>

              <div class="wrap" v-else> <!-- 4:해피머니 교환권, 5:구글카카오톡 교환권, 8:틴캐시 -->
                <div class="input-2">
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo0"
                         ref="pinNo0"/>
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo1"
                         ref="pinNo1"/>
                  <input inputmode="numeric" type="text" maxlength="4" placeholder="4자리" v-model="pinNo2"
                         ref="pinNo2"/>
                  <TCInput
                      :type="'text'"
                      :placeholder="'금액 입력'"
                      :model="amount"
                      :input-mode="'numeric'"
                      :max-length="10"
                      @update="(_model) => {this.amount=_model}"
                  />
                </div>
                <span class="btn_blue" @click="add()">상품권 추가</span>
              </div>
              <div class="wrap added_ticket" v-if="addedTicketList.length > 0">
                <div class="info">
                  <!-- 반복 -->
                  <ul v-for="(data, index) in addedTicketList" :key="index">
                    <li>{{ data.ticketName }}[{{ data.purchaseRate }}%]
                    </li>
                    <li> 핀번호 : {{ data.number }}</li>
                    <li> 금액 : {{ numberWithCommas(data.amount) }} 원</li>
                    <li class="delete">
                      <a @click="deleteTicket(index)">
                      </a>
                    </li>
                  </ul>
                  <!-- //반복 -->
                </div>
              </div>
            </div>
            <!-- //상품권번호입력 -->

            <!-- 상품권교환금액 -->
            <div class="money">
              <h3>상품권 교환 금액</h3>
              <ul>
                <li>
                  <label>신청금액</label>
                  <div>
                    <span>{{ numberWithCommas(applySum) }}원</span>
                  </div>
                </li>
                <li>
                  <label>교환수수료</label>
                  <div>
                    <span>{{ numberWithCommas(feeSum) }}원</span>
                  </div>
                </li>
                <li>
                  <label>이체수수료</label>
                  <div>
                    <span>{{ numberWithCommas(transferFee) }}원</span>
                  </div>
                </li>
                <li>
                  <label>실수령액</label>
                  <div>
                    <span>{{ numberWithCommas(realAmount) }}원</span>
                  </div>
                </li>
              </ul>
            </div>
            <!-- //상품권교환금액 -->

            <!-- 입금계좌정보 -->
            <div class="account">
              <h3>입금계좌 정보</h3>
              <ul>
                <li>
                  <a v-if="selectedBank === null" class="bank_sel" @click="openBankList()">은행 선택</a>
                  <a v-else class="bank_sel" @click="openBankList()">{{ selectedBank.bank_name }}</a>
                </li>
                <li>
                  <TCInput
                      :type="'text'"
                      :placeholder="'입금 받으실 계좌번호'"
                      :model="accountInfo.account"
                      :input-mode="'numeric'"
                      :max-length="20"
                      @update="(_model) => {this.accountInfo.account=_model}"
                  />
                </li>
                <li>
                  <TCInput
                      :type="'text'"
                      :placeholder="'고객명(예금주)'"
                      :model="accountInfo.name"
                      :max-length="30"
                      @update="(_model) => {this.accountInfo.name=_model}"
                  />
                </li>
                <li>
                  <TCInput
                      :type="'text'"
                      :placeholder="'휴대폰 번호'"
                      :model="accountInfo.phone"
                      :input-mode="'numeric'"
                      :max-length="20"
                      @update="(_model) => {this.accountInfo.phone=_model}"
                  />
                </li>
              </ul>
              <div class="remember"><input type="checkbox" id="rmb" v-model="chkAccountInfo"
                                           @click="rememberBankInfo()"/><label for="rmb">입금계좌
                정보 기억하기</label></div>
            </div>
            <!-- //입금계좌정보 -->
          </form>
          <div class="agree"><input type="checkbox" id="agr" v-model="chkTerms" @click="chkTerms = !chkTerms"/>
            <label
                for="agr">개인정보처리방침 동의
            </label>
            <a
                class="btn_privacy" @click="showTerms()">[보기]</a>
          </div>
          <a id="btn_blue_money" @click="apply()">상품권 현금 교환 신청</a>
        </div>
        <!-- //신청폼 -->

        <!-- 주의사항 -->
        <div class="caution">
          <div>
            <h3>[교환 신청시 주의사항]</h3>
            <ol>
              <li>1. 상품권 현금교환 신청서는 반드시 정확한 정보로 작성해 주셔야 합니다. 신청서에 오류가 있는 경우는 입금이 지연되거나 불가할 수 있습니다.</li>
              <li>2. (23:40~00:35)에는 은행 점검 시간이므로 입금이 불가능하며 점검시간이 끝난 후 순차적으로 입금됩니다.</li>
              <li>3. 오랫동안 입금이 지연되는 경우, 고객센터 ({{ customerCenter }})으로 연락하여 주시기 바랍니다.</li>
              <li>4. 현금교환이 완료된 상품권은, 접수취소가 불가능하오니 신중하게 접수바랍니다.</li>
              <li>5. 명의도용 및 도난상품권 제3자 사기로 인한 상품권을 판매 시 법적 처벌을 받을 수 있으며 수사기관에 적극 협조하고 있습니다.</li>
            </ol>
          </div>
          <div>
            <h3>[서비스 진행상태 안내]</h3>
            <ol>
              <li>1. 홈페이지 실시간 매입 현황 페이지를 통해 상품권 현금 교환 신청 진행 상태를 확인할 수 있습니다.</li>
              <li>2. 올바르게 신청하였으나 처리불가 혹은 일부입금 상태일 경우 고객센터 ({{ customerCenter }})으로 연락하여 주시기 바랍니다.</li>
            </ol>
            <ul>
              <li><span class="green">접수대기</span><strong>상품권 현금교환 신청이 정상적으로 접수되어 처리 대기중입니다.</strong></li>
              <li><span class="blue">입금완료</span><strong>성공적으로 신청서 처리가 완료된 상태입니다.</strong></li>
              <li><span class="pink">일부입금</span><strong>신청하신 상품권의 일부를 처리 할 수 없습니다.(실시간 매입현황 확인)</strong></li>
              <li><span class="gray">처리불가</span><strong>상품권 혹은 예금주가 부정확하여 처리가 불가능합니다.</strong></li>
            </ul>
          </div>
        </div>
        <!-- //주의사항 -->
      </div>
    </div>
    <!-- //container -->

    <apply-select-bank
        v-show="showBankList"
        :bank-list="bankList"
        @searched="setSearchedBank"
        @selected="setSelectedBank"
        @close="showBankList=false"
    />
  </div>
</template>

<script>
import ApplySelectBank from "@/components/apply/ApplySelectBank";
import TCInput from "@/components/common/TCInput";

export default {
  name: "ApplyExchangeCash",
  components: {
    TCInput,
    ApplySelectBank
  },
  data() {
    return {
      ticketList: [], // 상품권 리스트
      selectedTicket: null,

      placeHolder: '상품권을 구매하고 받으신 문자 내용을 이곳에 붙여 넣으신 후 ‘추출하기’ 버튼을 클릭하시면 핀번호가 자동으로 추출됩니다.\n' + 'ex)\n1234-1234-1234-1234\n1234123412341234',
      autoNumber: '',

      pinNo0: '',
      pinNo1: '',
      pinNo2: '',
      pinNo3: '',
      pinNo4: '',

      pinNo5: '',

      amount: '',
      addedTicketList: [],

      applySum: 0, // 신청금액
      feeSum: 0, // 교환수수료
      transferFee: 0, // 이체수수료
      realAmount: 0, // 실수령액

      showBankList: false,
      bankList: [], // 은행 정보
      searchKeyword: '',
      selectedBank: null,
      accountInfo: {  // 입금계좌정보
        account: '',
        name: '',
        phone: '',
      },
      chkAccountInfo: false, // 은행정보 기억
      chkTerms: true, // 개인정보처리방침 동의

      customerCenter: ''
    }
  },
  mounted() {
    this.scrollTop()

    if (localStorage.getItem('siteInfo')) {
      this.customerCenter = JSON.parse(localStorage.getItem('siteInfo')).customer_tel
    }

    if (localStorage.getItem('accountInfo') !== null) {
      this.chkAccountInfo = true
      let accountInfo = JSON.parse(localStorage.getItem('accountInfo'))

      if (accountInfo.bankCode !== '' && accountInfo.bankName !== '') {
        this.selectedBank = {'bank_code': accountInfo.bankCode, 'bank_name': accountInfo.bankName}
      }

      this.accountInfo.account = accountInfo.account
      this.accountInfo.name = accountInfo.name
      this.accountInfo.phone = accountInfo.phone
    }

    this.getTicketList()
    this.getBankList()
    this.getTransferFee()
  },
  beforeUnmount() {
    this.accountInfoCheck()
  },
  methods: {
    getTransferFee(){
      this.$get(this.$SITE_INFO, 'apply_exchange_cash_get_transfer_fee', (result) => {
        this.transferFee = result.data.transfer_fee

      }, (result) => {
        this.httpError(result)
      })
    },
    getTicketList() {  // 상품권 정보 가져오기
      this.$get(this.$TICKET_LIST, 'apply_exchange_cash_get_ticket_list', (result) => {
        this.ticketList = result.data
        this.selectedTicket = this.ticketList[0]

      }, (result) => {
        this.httpError(result)
      })
    },
    clearInput() {
      this.pinNo0 = ''
      this.pinNo1 = ''
      this.pinNo2 = ''
      this.pinNo3 = ''
      this.pinNo4 = ''
      this.pinNo5 = ''
      this.amount = ''
    },
    selectTicket(_ticket) {  // 상품권 선택
      this.selectedTicket = _ticket
      this.autoNumber = ''
      this.clearInput()

      this.setPlaceHolder(_ticket.id)
    },
    setPlaceHolder(_id) {
      switch (_id) {
        case 1:
        case 2:
        case 6:
          this.placeHolder = '상품권을 구매하고 받으신 문자 내용을 이곳에 붙여 넣으신 후 ‘추출하기’ 버튼을 클릭하시면 핀번호가 자동으로 추출됩니다.\n' + 'ex)\n1234-1234-1234-1234\n1234123412341234'
          return
        case 3:
          this.placeHolder = '상품권을 구매하고 받으신 문자 내용을 이곳에 붙여 넣으신 후 ‘추출하기’ 버튼을 클릭하시면 핀번호가 자동으로 추출됩니다.\n' + 'ex)\n1234-1234-1234_20230101\n1234-1234-1234-20230101\n123412341234_20230101\n1234-1234-1234-1234/20230101\n1234123412341234/20230101'
          return
        case 9:
          this.placeHolder = '상품권을 구매하고 받으신 문자 내용을 이곳에 붙여 넣으신 후 ‘추출하기’ 버튼을 클릭하시면 핀번호가 자동으로 추출됩니다.\n' + 'ex)\nA-123-1234-1234\nA12312341234'
          return
        default:
          this.placeHolder = '상품권을 구매하고 받으신 문자 내용을 이곳에 붙여 넣으신 후 ‘추출하기’ 버튼을 클릭하시면 핀번호가 자동으로 추출됩니다.\n' + 'ex)\n1234-1234-1234\n123412341234'
          return
      }
    },
    extractPinNumber() { // 자동추출
      if (this.autoNumber === '') {
        this.notify('error', '문자 내용을 입력해주세요')
        return
      }

      let pin = this.autoNumber.replaceAll('-', '').replaceAll('_', '').replaceAll('/', '').replaceAll(' ', '')

      if (this.selectedTicket.id === 9) {
        this.pinNo5 = pin.slice(0, 1)
        this.pinNo0 = pin.slice(1, 4)
        this.pinNo1 = pin.slice(4, 8)
        this.pinNo2 = pin.slice(8, 12)

      } else {
        this.pinNo0 = pin.slice(0, 4)
        this.pinNo2 = pin.slice(8, 12)
        this.pinNo1 = pin.slice(4, 8)

        if (this.selectedTicket.id === 1 || this.selectedTicket === 2 || this.selectedTicket.id === 3 || this.selectedTicket.id === 6) {
          this.pinNo3 = pin.slice(12, 16)
        }

        if (this.selectedTicket.id === 3) {
          this.pinNo4 = pin.slice(16, 24)
        } else if (this.selectedTicket.id === 6) {
          this.pinNo4 = pin.slice(16, 20)
        }
      }

      this.clearAutoNumber()
    },
    clearAutoNumber() {  // 비우기
      this.autoNumber = ''
    },
    getBankList() {
      let queryString = ''

      if (this.searchKeyword.trim() !== '') {
        queryString = '?searchWord=' + this.searchKeyword
      }

      this.$get(this.$BANK_LIST + queryString, 'apply_exchange_cash_get_bank_list', (result) => {
        this.bankList = result.data

      }, (result) => {
        this.httpError(result)
      })
    },
    openBankList() { // 은행 선택 팝업
      this.showBankList = true
    },
    setSearchedBank(_keyword) {
      this.searchKeyword = _keyword

      this.getBankList()
    },
    setSelectedBank(_bank) {
      this.selectedBank = _bank
      this.showBankList = false
    },
    rememberBankInfo() { // 은행정보 기억하기
      this.chkAccountInfo = !this.chkAccountInfo
      this.accountInfoCheck()
    },
    accountInfoCheck() {
      if (this.chkAccountInfo) {
        this.saveAccountInfo()
      } else {
        localStorage.removeItem('accountInfo')
      }
    },
    saveAccountInfo() {
      let accountInfo = {
        'account': this.accountInfo.account,
        'name': this.accountInfo.name,
        'phone': this.accountInfo.phone,
        'bankCode': '',
        'bankName': ''
      }
      if (this.selectedBank !== null) {
        accountInfo.bankCode = this.selectedBank.bank_code
        accountInfo.bankName = this.selectedBank.bank_name
      }

      localStorage.setItem('accountInfo', JSON.stringify(accountInfo))
    },
    showTerms() {  // 개인정보처리방침
      this.$root.$refs.Terms01.showTerms()
    },
    add() {  // 추가
      if (this.pinNo0 === '' || this.pinNo1 === '' || this.pinNo2 === '') {
        this.notify('error', '상품권 번호를 정확하게 입력해주세요')
        return
      }

      if ((this.selectedTicket.id === 1 || this.selectedTicket.id === 2 || this.selectedTicket.id === 3 || this.selectedTicket.id === 6) && this.pinNo3 === '') {
        this.notify('error', '상품권 번호를 정확하게 입력해주세요')
        return
      }

      if ((this.selectedTicket.id === 3 || this.selectedTicket.id === 6) && this.pinNo4 === '') {
        this.notify('error', '상품권 번호를 정확하게 입력해주세요')
        return
      }

      if ((this.selectedTicket.id === 9) && this.pinNo5 === '') {
        this.notify('error', '상품권 번호를 정확하게 입력해주세요')
        return
      }

      if (this.amount === '') {
        this.notify('error', '상품권 금액을 입력해주세요')
        return
      }

      if (!/^[0-9]*$/.test(this.amount)) {
        this.notify('error', '상품권 금액을 정확하게 입력해주세요')
        return
      }

      // 금액계산
      this.applySum = this.applySum + (this.amount * 1) // 신청금액
      this.feeSum = this.applySum * ((100 - (this.selectedTicket.purchase_rate * 1)) * 0.01) // 교환수수료
      this.realAmount = this.applySum - this.feeSum - this.transferFee

      let pinNo = ''
      if (this.selectedTicket.id === 9) {  /*컬쳐랜드 캐시플러스*/
        pinNo = this.pinNo5 + '-' + this.pinNo0 + '-' + this.pinNo1 + '-' + this.pinNo2

      } else {
        pinNo = this.pinNo0 + '-' + this.pinNo1 + '-' + this.pinNo2

        if (this.selectedTicket.id === 1 || this.selectedTicket.id === 2 || this.selectedTicket.id === 3 || this.selectedTicket.id === 6) {
          pinNo = pinNo + '-' + this.pinNo3

          if (this.selectedTicket.id !== 1 && this.selectedTicket.id !== 2) {
            pinNo = pinNo + '-' + this.pinNo4
          }
        }
      }

      this.addedTicketList.push({
        'ticketId': this.selectedTicket.id,
        'ticketName': this.selectedTicket.ticket_name,
        'purchaseRate': this.selectedTicket.purchase_rate,
        'number': pinNo,
        'amount': this.amount
      })

      this.clearInput()
    },
    deleteTicket(_index) {
      let ticket = this.addedTicketList[_index]

      if (this.addedTicketList.length > 1){
        // 금액계산
        this.applySum = this.applySum - (ticket.amount * 1) // 신청금액
        this.feeSum = this.applySum * ((100 - (this.selectedTicket.purchase_rate * 1)) * 0.01) // 교환수수료
        this.realAmount = this.applySum - this.feeSum - this.transferFee

      } else {
        this.applySum = 0
        this.feeSum = 0
        this.realAmount = 0
      }

      this.addedTicketList.splice(_index, 1)
    },
    apply() {  // 상품권 현금교환 신청
      if (this.addedTicketList.length < 1) {
        this.notify('error', '상품권을 추가해주세요')
        return
      }

      if (this.selectedBank.bank_code === '') {
        this.notify('error', '은행을 선택해주세요')
        return
      }
      if (this.accountInfo.account === '') {
        this.notify('error', '계좌번호를 입력해주세요')
        return
      }

      if (this.accountInfo.name === '') {
        this.notify('error', '고객명(예금주)을 입력해주세요')
        return
      }

      if (this.accountInfo.phone === '') {
        this.notify('error', '휴대폰 번호를 입력해주세요')
        return
      }

      if (this.chkTerms === false) {
        this.notify('error', '개인정보처리방침에 동의해주세요')
        return
      }

      let queryString = '?bankCode=' + this.selectedBank.bank_code + '&customerName=' + this.accountInfo.name + '&customerAccount=' + this.accountInfo.account
          + '&customerPhone=' + this.accountInfo.phone

      let culPin = ''
      let culExchangePin = ''
      let happyPin = ''
      let happyExchangePin = ''
      let googleExchangePin = ''
      let bookPin = ''
      let bookExchangePin = ''
      let teenPin = ''
      let culCashPin = ''

      let separateChar = '|'
      for (let i = 0; i < this.addedTicketList.length; i++) {
        let ticket = this.addedTicketList[i]

        if (ticket.ticketId === 1) {
          if (culPin === '') {
            culPin = ticket.number
          } else {
            culPin = culPin + separateChar + ticket.number
          }
        } else if (ticket.ticketId === 2) {
          if (culExchangePin === '') {
            culExchangePin = ticket.number
          } else {
            culExchangePin = culExchangePin + separateChar + ticket.number
          }
        } else if (ticket.ticketId === 3) {
          if (happyPin === '') {
            happyPin = ticket.number
          } else {
            happyPin = happyPin + separateChar + ticket.number
          }
        } else if (ticket.ticketId === 4) {
          if (happyExchangePin === '') {
            happyExchangePin = ticket.number
          } else {
            happyExchangePin = happyExchangePin + separateChar + ticket.number
          }
        } else if (ticket.ticketId === 5) {
          if (googleExchangePin === '') {
            googleExchangePin = ticket.number
          } else {
            googleExchangePin = googleExchangePin + separateChar + ticket.number
          }
        } else if (ticket.ticketId === 6) {
          if (bookPin === '') {
            bookPin = ticket.number
          } else {
            bookPin = bookPin + separateChar + ticket.number
          }
        } else if (ticket.ticketId === 7) {
          if (bookExchangePin === '') {
            bookExchangePin = ticket.number
          } else {
            bookExchangePin = bookExchangePin + separateChar + ticket.number
          }
        } else if (ticket.ticketId === 8) {
          if (teenPin === '') {
            teenPin = ticket.number
          } else {
            teenPin = teenPin + separateChar + ticket.number
          }
        } else if (ticket.ticketId === 9) {
          if (culCashPin === '') {
            culCashPin = ticket.number
          } else {
            culCashPin = culCashPin + separateChar + ticket.number
          }
        }
      }

      if (culPin !== '') {
        queryString = queryString + '&culPinNumber=' + culPin
      }
      if (culExchangePin !== '') {
        queryString = queryString + '&culExPinNumber=' + culExchangePin
      }
      if (happyPin !== '') {
        queryString = queryString + '&happyPinNumber=' + happyPin
      }
      if (happyExchangePin !== '') {
        queryString = queryString + '&happyExPinNumber=' + happyExchangePin
      }
      if (googleExchangePin !== '') {
        queryString = queryString + '&googleExPinNumber=' + googleExchangePin
      }
      if (bookPin !== '') {
        queryString = queryString + '&bookPinNumber=' + bookPin
      }
      if (bookExchangePin !== '') {
        queryString = queryString + '&bookExPinNumber=' + bookExchangePin
      }
      if (teenPin !== '') {
        queryString = queryString + '&teenPinNumber=' + teenPin
      }
      if (culCashPin !== '') {
        queryString = queryString + '&culCashPinNumber=' + culCashPin
      }

      this.$post(this.$ORDER_REGISTER + queryString, 'apply_exchange_cash_apply', null, () => {
        this.notify('success', '상품권 교환 신청이 완료되었습니다')

        // 신청정보 clear
        this.autoNumber = ''
        this.clearInput()

        this.addedTicketList = []

        this.applySum = 0
        this.feeSum = 0
        this.realAmount = 0

        if (!this.chkAccountInfo) {
          this.selectedBank = null
          this.accountInfo = {account: '', name: '', phone: ''}
        }
        this.accountInfoCheck()

      }, (result) => {
        this.httpError(result)
      })
    },
  },
  watch: {
    autoNumber() {
      if (!(/^[A-Z0-9/_-]*$/.test(this.autoNumber))) {
        this.autoNumber = /([A-Z0-9/_-]*)/.exec(this.autoNumber)[0]
      }
    },
    pinNo0() {
      if (!(/^[0-9]*$/.test(this.pinNo0))) {
        this.pinNo0 = /([0-9]*)/.exec(this.pinNo0)[0]
      }

      if (this.selectedTicket.id === 9) {
        if (this.pinNo0.length === 3) {
          this.$refs.pinNo1.focus()
        }

      } else {
        if (this.pinNo0.length === 4) {
          this.$refs.pinNo1.focus()
        }
      }
    },
    pinNo1() {
      if (!(/^[0-9]*$/.test(this.pinNo1))) {
        this.pinNo1 = /([0-9]*)/.exec(this.pinNo1)[0]
      }

      if (this.pinNo1.length === 4) {
        this.$refs.pinNo2.focus()
      }
    },
    pinNo2() {
      if (!(/^[0-9]*$/.test(this.pinNo2))) {
        this.pinNo2 = /([0-9]*)/.exec(this.pinNo2)[0]
      }

      if (this.pinNo2.length === 4) {
        if (this.$refs.pinNo3) {
          this.$refs.pinNo3.focus()
        }
      }
    },
    pinNo3() {
      if (!(/^[0-9]*$/.test(this.pinNo3))) {
        this.pinNo3 = /([0-9]*)/.exec(this.pinNo3)[0]
      }

      if (this.pinNo3.length === 4) {
        if (this.$refs.pinNo4) {
          this.$refs.pinNo4.focus()
        }
      }
    },
    pinNo4() {
      if (!(/^[0-9]*$/.test(this.pinNo4))) {
        this.pinNo4 = /([0-9]*)/.exec(this.pinNo4)[0]
      }
    },
    pinNo5() {
      if (!(/^[A-Z]*$/.test(this.pinNo5))) {
        this.pinNo5 = /([A-Z]*)/.exec(this.pinNo5)[0]
      }

      if (this.pinNo5.length === 1) {
        if (this.$refs.pinNo0) {
          this.$refs.pinNo0.focus()
        }
      }
    }
  },
}
</script>

<style scoped>

</style>