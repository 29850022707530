<template>
  <input
      :type="type"
      :placeholder="placeholder"
      v-model.trim="inputValue"
      @focusin="focusIn()"
      @focusout="focusOut()"
      :disabled="disable"
      :inputmode="inputMode"
      :maxlength="maxLength > 0 ? maxLength : ''"
  />
</template>

<script>
export default {
  name: "TCInput",
  props: {
    model: {
      required: true
    },
    placeholder: {
      required: false
    },
    type: {
      required: true
    },
    disable: {
      required: false
    },
    inputMode: {
      required: false,
      type: String,
      default: '',
    },
    maxLength: {
      required: false,
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      inputValue: '',
      isFocus: false,
    }
  },
  created() {
    this.inputValue = this.model
  },
  methods: {
    deleteAll() {
      this.inputValue = ''
      this.$emit('update', '')
    },
    focusIn() {
      this.isFocus = true
    },
    focusOut() {
      this.isFocus = false
    },
  },
  watch: {
    inputValue() {
      if (this.inputMode === 'decimal') {
        if (/\.([0-9]*)\./.test(this.inputValue)) {
          this.inputValue = /([0-9]*)\.([0-9]*)/.exec(this.inputValue)[0]
        } else if (/^\./.test(this.inputValue)) {
          this.inputValue = ''
        }
      } else if (this.inputMode === 'numeric') {
        if (!(/^[0-9]*$/.test(this.inputValue))) {
          this.inputValue = /([0-9]*)/.exec(this.inputValue)[0]
        }
      }

      this.$emit('update', this.inputValue)
    },
    model() {
      this.inputValue = this.model
    }
  }
}
</script>

<style scoped>

</style>