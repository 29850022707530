<template>
  <div>
    <!-- 서브상단타이틀 -->
    <div class="visual_sub customer">
      <h2>공지사항</h2>
      <p>고객센터 및 상품권 매입 <br />24시간 운영됩니다</p>
    </div>
    <!-- //서브상단타이틀 -->
    <!-- container -->
    <div id="container" class="customer notice_view">
      <div class="wrap">
        <!-- 게시판상단 -->
        <div class="heading">
          <h3>{{ title }}</h3>
          <ul>
            <li>{{ date }}</li>
            <li>{{ name }}</li>
            <li>조회 : {{ numberWithCommas(views) }} </li>
          </ul>
        </div>
        <!-- //게시판상단 -->
        <!-- 게시판내용 -->
        <div class="con" v-html="content">
        </div>
        <!-- //게시판내용 -->
        <a class="btn_back" @click="goList()">목록으로 돌아가기</a>
      </div>
    </div>
    <!-- //container -->
  </div>
</template>

<script>
export default {
  name: "NoticeDetail",
  data() {
    return {
      id: 0,
      date: '',
      name: '',
      title: '',
      content: '',
      views: 0,
    }
  },
  mounted() {
    this.id = this.$route.query.id

    this.getNoticeDetail()
  },
  methods: {
    getNoticeDetail() {  // 공지사항 상세 가져오기
      this.$get(this.$BOARD_NOTICE_DETAIL + this.id, 'notice_detail_get_notice_detail', (result) => {
        this.date = result.data.created_at
        this.name = result.data.name
        this.title = result.data.title
        this.content = result.data.content
        this.views = result.data.views

      }, (result) => {
        this.httpError(result)
      })
    },
    goList() { // 목록으로 돌아가기
      this.$router.replace({name: 'NoticeList'})
    }
  }
}
</script>

<style scoped>

</style>