<template>
  <!-- 실수령액계산기기 -->
  <div id="pop_won" class="pop_layer" v-if="show" @click.self="close()">
    <h2>실수령액 계산기</h2>
    <div class="write">
      <span>상품권 교환 금액</span><input inputmode="numeric" type="text" placeholder="0" v-model="amount" maxlength="10"/><em>원</em>
    </div>
    <ul class="tab scrollBar" :class="{'h-132px': ticketList.length > 9 }">
      <!-- 반복 -->
      <li v-for="(ticket, index) in ticketList" :key="index" @click="selectTicket(ticket)">
        <input type="radio" :id="'tabc' + index" name="tabc" :checked="ticket.id === selectedTicket.id"/>
        <label :for="'tabc' + index">{{ ticket.ticket_name }}</label>
      </li>
      <!-- //반복 -->
    </ul>
    <div class="info">
      <ul>
        <li v-if="(amount * 1) >= 1000"><strong>상품권 교환 금액</strong><span>{{ numberWithCommas(amount) }}원</span></li>
        <li v-else><strong>상품권 교환 금액</strong><span>0원</span></li>
        <li><strong>매입 수수료율</strong><span>{{ numberWithCommas(purchaseRate) }}%</span></li>
        <li><strong>계좌이체 수수료</strong><span>{{ numberWithCommas(transferFee) }}원</span></li>
      </ul>
      <dl>
        <dt>입금 예상 금액</dt>
        <dd v-if="(amount * 1) >= 1000"><span>{{ numberWithCommas(depositAmount) }}</span><em>원</em></dd>
        <dd v-else><span>0</span><em>원</em></dd>
      </dl>
    </div>
    <a class="pop_close" @click="close()">닫기</a>
  </div>
  <!-- //실수령액계싼기 -->
</template>

<script>
export default {
  name: "Calculator",
  data() {
    return {
      show: false,
      ticketList: [],
      amount: '',
      selectedTicket: null, // 상품권 타입
      transferFee: 0, // 계좌이체 수수료
      purchaseRate: 0, // 매입 수수료율
      depositAmount: 0, // 입금 예정 금액
    }
  },
  methods: {
    getTransferFee(){
      this.$get(this.$SITE_INFO, 'calculator_get_transfer_fee', (result) => {
        this.transferFee = result.data.transfer_fee

      }, (result) => {
        this.httpError(result)
      })
    },
    getTicketList() {  // 상품권 정보 가져오기
      this.$get(this.$TICKET_LIST, 'calculator_get_ticket_list', (result) => {
        this.ticketList = result.data
        this.selectedTicket = this.ticketList[0]
        this.purchaseRate = 100 - (this.selectedTicket.purchase_rate * 1)

        this.show = true

      }, (result) => {
        this.httpError(result)
      })
    },
    showCalculator() {
      this.amount = ''
      this.depositAmount = 0
      this.getTicketList()
      this.getTransferFee()
    },
    selectTicket(_ticket) {  // 상품권 선택
      this.selectedTicket = _ticket

      this.purchaseRate = 100 - (this.selectedTicket.purchase_rate * 1)
      this.calculate()
    },
    calculate() { // 입금예정금액 계산
      // 입금예정금액 = 교환금액 - 매입 수수료 - 계좌이체 수수료
      this.depositAmount = (this.amount * 1) - ((this.amount * 1) * (this.purchaseRate * 0.01)) - this.transferFee
    },
    close() {
      this.show = false
    },
  },
  watch: {
    amount() {
      if (!(/^[0-9]*$/.test(this.amount))) {
        this.amount = /([0-9]*)/.exec(this.amount)[0]
      }

      if ((this.amount * 1) >= 1000) {
        this.calculate()
      }
    },
  },
}
</script>

<style scoped>

</style>