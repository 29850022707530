<template>
  <div>
    <!-- 서브상단타이틀 -->
    <div class="visual_sub order_view">
      <h2>신청내역 상세보기</h2>
      <p>고객님의 신청정보입니다. <br/>현재 페이지는 실시간으로 업데이트 됩니다.</p>
    </div>
    <!-- //서브상단타이틀 -->
    <!-- container -->
    <div id="container" class="order_view">
      <div class="wrap" v-if="data != null">
        <div class="form_left">

          <div class="box_wrapper h909">
            <h3>처리상태</h3>
            <!-- green(접수대기) : 0, blue(입금완료) : 1 , pink(일부입금) : 2, gray(처리불가) : 3  -->
            <div v-if="data.status === '0'" class="progress_bar p50 flex">
              <p class="progress">주문접수</p>
              <p class="progress">처리중</p>
              <p class="progress">입금완료</p>
            </div>
            <div v-if="data.status === '1'" class="progress_bar p100 flex">
              <p class="progress">주문접수</p>
              <p class="progress">처리중</p>
              <p class="progress">입금완료</p>
            </div>
            <div v-if="data.status === '2'" class="progress_bar p100 flex">
              <p class="progress">주문접수</p>
              <p class="progress">처리중</p>
              <p class="progress">일부입금</p>
            </div>
            <div v-if="data.status === '3'" class="progress_bar p100 flex">
              <p class="progress">주문접수</p>
              <p class="progress">매입상품권 없음</p>
              <p class="progress">처리불가</p>
            </div>
            <h2 v-if="data.status === '3'">매입이 불가해요.</h2>
            <p v-if="data.status === '3'" class="desc">등록하신 상품권은 매입이 불가합니다.<br>처리불가 상품권을 확인해주세요.</p>
          </div>

          <div class="box_wrapper pin">
            <h2>상품권 목록</h2>
            <a class="reload" @click="getStatusDetail()">새로고침</a>
            <ul>
              <!-- 반복 -->
              <li v-for="(ticket, index) in data.tickets" :key="index">
                <div class="left">
                  <em :style="{ background: 'url(' + $DOMAIN + ticket.ticket_img + ') no-repeat center center/100%'}"></em>
                  <span>{{ index + 1 }}. {{ ticket.ticket_name }}</span>
                  <p>{{ ticket.pin_number }}</p>
                </div>
                <div class="right">
                  <p>{{ ticket.memo }}</p>
                  <!-- green(접수대기) : 0, blue(입금완료) : 1, gray(처리불가) : 2 -->
                  <span
                      :class="[{'green' : ticket.status === '0'}, {'blue' : ticket.status === '1'}, {'gray' : ticket.status === '2'}]">{{
                    ticketStatus(ticket.status) }}</span>
                </div>
              </li>
              <!--// 반복 -->
            </ul>
          </div>
        </div>
        <div class="form_right">

          <div class="box_wrapper v909">
            <h3>처리상태</h3>
            <!-- green(접수대기) : 0, blue(입금완료) : 1 , pink(일부입금) : 2, gray(처리불가) : 3  -->
            <div v-if="data.status === '0'" class="progress_bar p50 flex">
              <p class="progress">주문접수</p>
              <p class="progress">처리중</p>
              <p class="progress">입금완료</p>
            </div>
            <div v-if="data.status === '1'" class="progress_bar p100 flex">
              <p class="progress">주문접수</p>
              <p class="progress">처리중</p>
              <p class="progress">입금완료</p>
            </div>
            <div v-if="data.status === '2'" class="progress_bar p100 flex">
              <p class="progress">주문접수</p>
              <p class="progress">처리중</p>
              <p class="progress">일부입금</p>
            </div>
            <div v-if="data.status === '3'" class="progress_bar p100 flex">
              <p class="progress">주문접수</p>
              <p class="progress">매입상품권 없음</p>
              <p class="progress">처리불가</p>
            </div>
            <h2 v-if="data.status === '3'">매입이 불가해요.</h2>
            <p v-if="data.status === '3'" class="desc">등록하신 상품권은 매입이 불가합니다.<br>처리불가 상품권을 확인해주세요.</p>
          </div>

          <article class="box_wrapper author">

            <div class="flex gap10 inline">
              <div class="flex order_info">
                <p>주문번호 ({{ data.id }})</p>
                <p>{{ data.date }}</p>
              </div>
              <div class="bank_info flex">
                <img class="js_bank_img" :src="$DOMAIN + data.bank_img" alt="은행로고">
                <span>{{ data.bank_name }}</span>
              </div>
              <div class="input_wrap">
                <input type="text" v-model="data.customer_account" disabled/>
                <span>입금 계좌</span>
              </div>
              <div class="input_wrap">
                <input type="text" name="author" v-model="data.customer_name" disabled/>
                <span>고객명 (예금주명)</span>
              </div>
              <div class="input_wrap">
                <input type="text" class="pointer__none" v-model="data.customer_phone" disabled/>
                <span>휴대폰 번호</span>
              </div>
            </div>

            <div class="detail_info">
              <ul>
                <li>
                  <p>상품권 수량</p>
                  <p><strong>{{ completedTicketCnt }}</strong>/ {{ data.tickets.length }}</p>
                </li>
                <li>
                  <p>매입 총금액</p>
                  <p><strong>{{ numberWithCommas(data.price) }}</strong>원</p>
                </li>
                <li>
                  <p>매입 수수료 (-)</p>
                  <p class="detail_fee"><strong>{{ numberWithCommas(data.purchase_fee) }}</strong>원</p>
                </li>
                <li>
                  <p>이체 수수료 (-)</p>
                  <p class="detail_fee"><strong>{{ numberWithCommas(data.transfer_fee) }}</strong>원</p>
                </li>
                <li>
                  <p>입금(예정) 금액</p>
                  <p><strong class="result_price">{{ numberWithCommas(data.deposit) }}</strong>원</p>
                </li>
              </ul>
              <a class="flex" @click="$router.back()">
                <i class="xi-long-arrow-left"></i> 목록으로 돌아가기
              </a>
            </div>

          </article>
        </div>
      </div>
      <div v-else class="before-load">
      </div>
    </div>
    <!-- //container -->
  </div>
</template>

<script>
export default {
  name: "RealTimePurchaseStatusDetail",
  data() {
    return {
      id: 0,
      data: null,
      completedTicketCnt: 0,
    }
  },
  mounted() {
    this.id = this.$route.query.id

    this.getStatusDetail()
  },
  methods: {
    getStatusDetail() {
      this.$get(this.$MYORDER_DETAIL + this.id , 'real_time_purchase_status_detail_get_status_detail', (result) => {
        this.data = result.data

        this.completedTicketCnt = 0
        for (let i=0 ; i < this.data.tickets.length ; i++){
          let ticket = this.data.tickets[i]

          if (ticket.status === '1'){
            this.completedTicketCnt += 1
          }
        }

      }, (result) => {
        this.httpError(result)
        this.$router.back()
      })
    }
  },
}
</script>

<style scoped>

</style>