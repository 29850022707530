import axios from 'axios';

let requestOption = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        timeout: 60000
    }
}
let requestOptionWithToken = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Token': sessionStorage.getItem('myOrderToken')
        },
        timeout: 60000
    }
}

let pending_get = {}
let pending_post = {}
let pending_put = {}
let pending_delete = {}

export default Object.freeze({
    install(Vue) {
        Vue.config.globalProperties.$DOMAIN = process.env.VUE_APP_DOMAIN

        Vue.config.globalProperties.$SITE_INFO = "/siteInfo" /*사이트 정보*/
        Vue.config.globalProperties.$BANK_LIST = "/bank" /*은행 리스트*/
        Vue.config.globalProperties.$TICKET_LIST = "/ticket" /*상품권 매입시세*/

        Vue.config.globalProperties.$MYORDER_GET_TOKEN = "/myPage/getToken" /*내 주문 조회 토큰 발행*/
        Vue.config.globalProperties.$MYORDER_LIST = "/myPage" /*내 주문 조회 리스트*/
        Vue.config.globalProperties.$MYORDER_DETAIL = "/myPage/" /*내 주문 조회 상세 :id*/

        Vue.config.globalProperties.$ORDER_LIST = "/order" /*실시간매입 현황*/

        Vue.config.globalProperties.$ORDER_REGISTER = "/order" /*현금 교환 신청*/

        Vue.config.globalProperties.$BOARD_NOTICE_LIST = "/board/notice" /*공지사항 가져오기*/
        Vue.config.globalProperties.$BOARD_NOTICE_DETAIL = "/board/notice/" /*공지사항 상세 :id*/

        Vue.config.globalProperties.$BOARD_FAQ_LIST = "/board/faq" /*faq 가져오기*/

        Vue.config.globalProperties.$BOARD_QNA_LIST = "/board/qna" /*문의사항 가져오기*/
        Vue.config.globalProperties.$BOARD_QNA_DETAIL = "/board/qna/" /*문의사항 상세 :id*/
        Vue.config.globalProperties.$BOARD_QNA_REGISTER = "/board/qna" /*문의사항 등록*/

        Vue.config.globalProperties.$BOARD_GET_CAPTCHA = "/board/getCaptcha" /*캡챠 정보 가져오기*/

        Vue.config.globalProperties.$post = function (callUrl, caller, postData, success, fail) {
            if (pending_post[arguments[0] + caller]) {
                console.log('request post fail : ' + arguments[0] + caller)
                return
            }
            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOption()

            axios.post(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_post[arguments[0] + caller] = false

                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch((e) => {
                pending_post[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.config.globalProperties.$put = function (callUrl, caller, postData, success, fail) {
            if (pending_put[arguments[0] + caller]) {
                console.log('request put fail : ' + arguments[0] + caller)
                return
            }
            pending_put[arguments[0] + caller] = true
            let _requestOption = requestOption()

            axios.put(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_put[arguments[0] + caller] = false

                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch((e) => {
                pending_put[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.config.globalProperties.$delete = function (callUrl, caller, success, fail) {
            if (pending_delete[arguments[0] + caller]) {
                console.log('request delete fail : ' + arguments[0] + caller)
                return
            }
            pending_delete[arguments[0] + caller] = true
            let _requestOption = requestOption()

            axios.delete(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_delete[arguments[0] + caller] = false

                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch((e) => {
                pending_delete[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.config.globalProperties.$get = function (callUrl, caller, success, fail) {
            if (pending_get[arguments[0] + caller]) {
                console.log('request get fail : ' + arguments[0] + caller)
                return
            }
            pending_get[arguments[0] + caller] = true
            let _requestOption = requestOption()

            if (callUrl.includes(this.$MYORDER_LIST)) {
                let token = sessionStorage.getItem('myOrderToken')
                if (token && token.length > 0) {
                    _requestOption = requestOptionWithToken()
                }
            }

            axios.get(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_get[arguments[0] + caller] = false

                if (callUrl.includes(this.$BOARD_GET_CAPTCHA)) {    // 1:1문의 캡챠정보 가져오기
                    success(response.data)
                } else {
                    if (response.data.result === 'success') {
                        success(response.data)
                    } else {
                        fail(response.data)
                    }
                }

            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                fail(e.response)
            })
        }
    }
})