<template>
  <div>
    <!-- 서브상단타이틀 -->
    <div class="visual_sub live">
      <h2>내 주문 내역</h2>
      <p>현금교환 신청하신 실시간 매입 현황입니다 <br/>순차적으로 처리해드리고 있습니다</p>
    </div>
    <!-- //서브상단타이틀 -->
    <!-- container -->
    <div id="container" class="live">
      <div class="wrap">
        <ul class="heading">
          <li class="num">주문번호</li>
          <li class="tt">신청내역</li>
          <li class="price">매입금액(원)</li>
          <li class="name">고객명</li>
          <li class="date">신청날짜</li>
          <li class="state">처리상태</li>
        </ul>
        <!-- 내 주문 내역 없을 때 -->
        <div v-if="loaded && myOrderList.length === 0" class="no-data">
          <p>#내 주문 내역이 없습니다.</p>
        </div>
        <!-- 내 주문 내역 있을 때 -->
        <ul v-if="myOrderList.length > 0" class="con">
          <!-- 반복 -->
          <li v-for="(data, index) in myOrderList" :key="index" @click="goDetail(data.id)">
            <div class="num">{{ data.id }}</div>
            <em class="ico"
                :style="{ background: 'url(' + $DOMAIN + data.ticket_img + ') no-repeat center center/100%'}"></em>
            <div class="tt">
              <span>{{ data.ticket_name }} {{ data.cnt }}건</span>
              <!--              <em>N</em>-->
            </div>
            <div v-if="data.status === '1' || data.status === '2'" class="price">{{ numberWithCommas(data.purchase) }}</div>
            <div v-else class="hidden-price">&nbsp;</div>
            <div class="name">
              <em class="bk01"
                  :style="{ background: 'url(' + $DOMAIN + data.bank_img + ') no-repeat center center/100%'}"></em><span>{{
                data.customer_name
              }}</span>
            </div>
            <div class="date">{{ data.created_at }}</div>
            <div class="state">
                 <span
                     :class="[{'green' : data.status === '0'}, {'blue' : data.status === '1'}, {'pink' : data.status === '2'}, {'gray' : data.status === '3'}]">
                   {{ orderStatus(data.status) }}
                 </span>
            </div>
          </li>
          <!-- //반복 -->
        </ul>
        <div class="paging" v-if="pages.length > 0">
          <ul>
            <li><a class="arr prev" @click="goPrev()">이전페이지</a></li>
            <!-- 반복 -->
            <li v-for="(page, index) in pages" :key="index">
              <a :class="{ 'on' : currentPage === page }" @click="getOrderList(page)">{{ page }}</a>
            </li>
            <!-- //반복 -->
            <li><a class="arr next" @click="goNext()">다음페이지</a></li>
          </ul>
        </div>
      </div>
    </div>
    <!-- //container -->
  </div>
</template>

<script>
export default {
  name: "MyOrder",
  data() {
    return {
      loaded: false,
      myOrderList: [],
      currentPage: 1,
      size: 15, // 한 페이지에 보여줄 size
      totalSize: 0, // 총 데이터 list length
      totalPage: 0,
      pages: [],
      ablePrev: false,
      ableNext: false,
    }
  },
  mounted() {
    this.scrollTop()

    this.getOrderList(1)
  },
  methods: {
    getOrderList(_page) {  // 내 주문 목록 가져오기
      this.loaded = false

      this.$get(this.$MYORDER_LIST + '?page=' + _page, 'my_order_get_order_list', (result) => {
        this.size = result.data.per_page
        this.totalSize = result.data.total
        this.myOrderList = result.data.data

        this.currentPage = _page
        this.pagination()

        this.loaded = true

      }, (result) => {
        this.httpError(result)
      })
    },
    goPrev() { /*이전*/
      if (this.ablePrev) {
        this.getOrderList(this.currentPage - 1)
      }
    },
    goNext() { /*다음*/
      if (this.ableNext) {
        this.getOrderList(this.currentPage + 1)
      }
    },
    pagination() {
      if (this.totalSize > this.size * this.currentPage) {
        this.ableNext = true
      } else {
        this.ableNext = false
      }
      this.ablePrev = (this.currentPage === 1 ? false : true)

      this.totalPage = Math.floor(this.totalSize % this.size === 0 ? this.totalSize / this.size : this.totalSize / this.size + 1)
      this.pages = []

      let startPage = (this.currentPage - 2) > 1 ? this.currentPage - 2 : 1 // 현재페이지 -2 ==> 1보다 크면, page-2 아니면 1
      let endPage = (this.currentPage + 2) > this.totalPage ? this.totalPage : this.currentPage + 2 // 현재페이지 +2 ==> 토탈보다 크면, 토탈 아니면 page+2

      if ((this.currentPage === 1 || this.currentPage === 2) && this.totalPage >= 5) { // 현재페이지 1 or 2이고, 토탈 5랑 같거나 크면 5
        endPage = 5
      } else if (this.currentPage === 1 && this.totalPage === 4) { // 현재페이지 1, 토탈 4
        endPage = 4
      }

      if (this.currentPage === this.totalPage) {
        if ((this.currentPage - 4) > 1) {
          startPage = this.currentPage - 4
        } else {
          startPage = 1
        }
      } else if (this.currentPage === (this.totalPage - 1)) {
        if ((this.currentPage - 3) > 1) {
          startPage = this.currentPage - 3
        } else {
          startPage = 1
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        this.pages.push(i)
      }
    },
    goDetail(_id) { // 내 주문 조회 상세
      this.$router.push({name: 'MyOrderDetail', query: {id: _id}})
    },
  },
}
</script>

<style scoped>

</style>